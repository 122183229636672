<template>
  <div :class="{ 'table-wrapper': responsive }">
    <b-badge
      v-if="!border"
      style="border-radius: 5px 5px 0px 0px"
      href="#"
      :variant="bordersColors[currentBorder]"
      @click="borderChange"
    >
      <feather-icon icon="PlusIcon" />
    </b-badge>
    <table>
      <thead :class="[skin == 'light' ? 'thead-bg-light' : 'thead-bg-dark']">
        <tr>
          <th
            v-for="(f, index) in configs"
            :key="index"
            :class="[
              currentBorder
                ? `${borders[currentBorder]}-border`
                : `${border}-border`,
              f.orderBy ? 'cursor-pointer' : '',
            ]"
            @click="orderBy(f)"
          >
            <span v-if="f.tooltip" v-b-tooltip.hover.right="f.tooltip">
              {{ f.label }}
            </span>
            <span v-else>
              {{ f.label }}
            </span>
            <feather-icon
              v-if="f.orderBy"
              :icon="f.orderBy.ascOrDes ? 'ChevronDownIcon' : 'ChevronUpIcon'"
              :class="[f.key == orderByKey ? 'text-success' : 'text-primary']"
            />
          </th>
        </tr>
      </thead>
      <tbody v-if="listView && listView.length > 0">
        <tr
          :class="[skin == 'light' ? 'row-light' : 'row-dark']"
          v-for="(l, index) in listView"
          :key="index"
          @click="rowClick(l)"
        >
          <td
            v-for="(f, index) in configs"
            :key="index"
            :class="[
              currentBorder
                ? `${borders[currentBorder]}-border`
                : `${border}-border`,
              f.class ? f.class : '',
            ]"
          >
            <slot :item="l" :name="f.key"> {{ l[f.key] }} </slot>
          </td>
        </tr>
      </tbody>
      <tbody class="text-center" v-else>
        <tr>
          <td class="error-msg" :colspan="configs.length">
            Nenhum dado encontrado
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { VBTooltip } from "bootstrap-vue";
import useAppConfig from "@core/app-config/useAppConfig";
export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      currentBorder: 0,
      borders: ["none", "simple", "full"],
      bordersColors: ["light-dark", "secondary", "dark"],
      listView: [],
      orderByKey: "",
      ascOrDes: false,
      configs: [],
    };
  },
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  props: {
    responsive: {
      type: Boolean,
    },
    border: {
      type: String,
    },
    fields: {
      type: Array,
      required: true,
    },
    list: {
      type: Array,
    },
  },
  watch: {
    list: {
      handler(newValue, oldValue) {
        this.listView = newValue;
      },
      deep: true,
      immediate: true,
    },
    fields: {
      handler(newValue, oldValue) {
        this.configs = newValue.map((value) => {
          if (!value.orderBy) return value;
          value.orderBy.ascOrDes = false;
          return value;
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    borderChange() {
      if (this.currentBorder < this.borders.length - 1) {
        this.currentBorder += 1;
      } else {
        this.currentBorder = 0;
      }
    },
    rowClick(item) {
      this.$emit("rowClick", item);
    },
    orderBy(item) {
      if (!item.orderBy) return;
      if (item.orderBy.type && item.orderBy.type == "front") {
        item.orderBy.ascOrDes = !item.orderBy.ascOrDes;
        this.ascOrDes = item.orderBy.ascOrDes;
        this.orderByKey = item.key;
        this.listView.sort(this.compare);
      } else if (item.orderBy.type && item.orderBy.type == "back") {
        item.orderBy.ascOrDes = !item.orderBy.ascOrDes;
        this.orderByKey = item.key;
        let payload = {
          ascOrDes: item.orderBy.ascOrDes,
          orderByKey: item.orderBy.customKey
            ? item.orderBy.customKey
            : this.orderByKey,
        };
        this.$emit("orderBy", payload);
      }
    },
    compare(a, b) {
      if (this.ascOrDes) {
        if (a[this.orderByKey] < b[this.orderByKey]) return -1;
        if (a[this.orderByKey] > b[this.orderByKey]) return 1;
      } else {
        if (a[this.orderByKey] > b[this.orderByKey]) return -1;
        if (a[this.orderByKey] < b[this.orderByKey]) return 1;
      }
      return 0;
    },
  },
};
</script>

<style scoped>
.table-wrapper {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
table {
  width: 100%;
}
table tr th,
td {
  padding: 10px 15px 10px 15px;
}
thead {
  text-transform: uppercase;
  font-size: 8pt;
}
.thead-bg-light {
  background-color: rgba(75, 75, 75, 0.12);
}
.thead-bg-dark {
  background-color: #2E353B;
}
table tbody tr {
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}

.row-light:hover {
  background-color: rgba(243, 243, 243, 0.712);
}

.row-dark:hover {
  background-color: #2E353B;
}

.simple-border {
  border-bottom: solid 1px rgb(226, 226, 226);
}

.full-border {
  border: solid 1px rgb(226, 226, 226);
}

.error-msg {
  padding-top: 30px;
  padding-bottom: 30px;
}
</style>
